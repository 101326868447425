<template>

	<div >
		<h3>直播 组件需要引入live组件在页面使用</h3>


		<div>
			<button @click="startLive">发起直播</button>
		</div>

		<div style="width: 1200px;height: 800px"  v-if="living">
			<live :role="role" :liveId="joinLiveId" :onCreateLive="onCreateLive" :on-close="onCloseLive"></live>
		</div>

		<hr style="margin: 50px 0">

		<div>
			liveId:<input type="text" v-model="joinLiveId">
			<button @click="joinLive">加入直播</button>
		</div>
	</div>

</template>

<script>

import Live from "@/components/common/Live.vue";

export default {
	name: "test1",
	components:{
		Live
	},
	computed:{
		user() {
			return this.$store.state.user;
		},
		is_login() {
			return this.$store.state.is_login;
		},
	},
	data(){
		return{
			startLiveId:"",
			joinLiveId:"",
			living:false,
			role:""// anchor：“主播”  audience：“观众”
		}
	},
	mounted() {

	},
	methods:{
		onCloseLive(){
			console.log("onCloseLive")
			this.startLiveId = "";
			this.joinLiveId = "";
			this.living = false;
			this.role = "";
		},
		onCreateLive(liveId){
			this.startLiveId = liveId;
		},
		startLive(){
			this.role = "anchor";
			this.living = true;
		},
		joinLive(){
			this.role = "audience";
			this.living = true;
		}

	}
}
</script>

<style scoped>
</style>